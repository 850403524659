import { initializeApp } from "firebase/app";
import { db_env } from "shared/config.js";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfigProd = {
  apiKey: "AIzaSyCWMTsBPSHvK_mo2da6RjALxBkYQfd1Rrk",
  authDomain: "abstruse-72090.firebaseapp.com",
  projectId: "abstruse-72090",
  storageBucket: "abstruse-72090.appspot.com",
  messagingSenderId: "366700065189",
  appId: "1:366700065189:web:8f12de8048fd453e4d784c",
  measurementId: "G-1GZT7QEGN3",
};

const firebaseConfigDev = {
  apiKey: "AIzaSyBPjX5z-h3eugKq2YS1bJU76xyuxhP5NhI",
  authDomain: "abstruse-dev.firebaseapp.com",
  projectId: "abstruse-dev",
  storageBucket: "abstruse-dev.appspot.com",
  messagingSenderId: "797819506008",
  appId: "1:797819506008:web:d6aee4421a40a8f42ba92d",
};

export const app =
  db_env === "prod"
    ? initializeApp(firebaseConfigProd)
    : initializeApp(firebaseConfigDev);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

// const analytics = getAnalytics(app);
