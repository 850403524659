import { formatDistanceToNow } from "date-fns";

export const formatTimestamp = (timestamp) => {
  try {
    if (!timestamp) {
      throw new Error("Timestamp is undefined or null.");
    }

    let date;
    if (typeof timestamp.seconds === "number") {
      date = new Date(timestamp.seconds * 1000);
    } else if (typeof timestamp._seconds === "number") {
      date = new Date(timestamp._seconds * 1000);
    } else {
      throw new Error("Timestamp structure is incorrect.");
    }

    return formatDistanceToNow(date, { addSuffix: true });
  } catch (error) {
    console.error("Error formatting timestamp:", error);
    return "Invalid time";
  }
};
