import React from "react";
import { Card, Space, Switch, Divider, List, Button, Form, Input } from "antd";
import { MailOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";

export default function EmailSetting({
  showEmail,
  handleEmailVisibility,
  emails,
  resendVerification,
  removeEmail,
  user,
  newEmail,
  setNewEmail,
  addNewEmail,
}) {
  return (
    <Card
      title={
        <Space>
          <MailOutlined className="text-blue-500" />
          <span>Email Settings</span>
        </Space>
      }
      className="shadow-md"
    >
      <Switch
        className="my-2"
        checkedChildren="Email Address Visible to Public"
        unCheckedChildren="Email Address Hidden"
        checked={showEmail}
        onChange={handleEmailVisibility}
      />

      <Divider />

      <List
        itemLayout="horizontal"
        dataSource={emails}
        renderItem={(item, index) => (
          <List.Item
            actions={[
              <Button
                key="verify"
                type="link"
                onClick={() => resendVerification(item.email)}
                disabled={user.emailVerified && item.isPrimary}
              >
                {user.emailVerified && item.isPrimary ? "Verified" : "Verify"}
              </Button>,
              !item.isPrimary && (
                <Button
                  key="delete"
                  type="link"
                  danger
                  onClick={() => removeEmail(item.email)}
                >
                  <DeleteOutlined />
                </Button>
              ),
            ]}
          >
            <List.Item.Meta
              title={
                item.isPrimary ? "Primary Email" : `Secondary Email ${index}`
              }
              description={item.email}
            />
          </List.Item>
        )}
      />

      <Divider />

      <Form layout="inline" onFinish={addNewEmail}>
        <Form.Item>
          <Input
            placeholder="Add new email"
            value={newEmail}
            onChange={(e) => setNewEmail(e.target.value)}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" icon={<PlusOutlined />}>
            Add Email
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
}
