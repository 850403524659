import topics from "./topics.json";

// Transform all available topics
export const all_topics = topics.topics.map((topic) => {
  if (typeof topic === "string") {
    return topic;
  } else {
    return Object.keys(topic)[0];
  }
});
export const all_topics_label_value = topics.topics.map((topic) => {
  if (typeof topic === "string") {
    return {
      label: topic,
      value: topic,
    };
  } else {
    return {
      label: Object.keys(topic)[0],
      value: Object.keys(topic)[0],
    };
  }
});
