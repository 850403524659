import {
  Code,
  Psychology,
  Science,
  Biotech,
  HealthAndSafety,
  Casino,
  Business,
  Work,
  SmartToy,
  Architecture,
  Translate,
  RemoveRedEye,
} from '@mui/icons-material';

export const topicIcons = {
  'Machine Learning': SmartToy,
  'Deep Learning': Biotech,
  'Reinforcement Learning': Psychology,
  'Neural Networks': Architecture,
  'Natural Language Processing': Translate,
  'Computer Vision': RemoveRedEye,
  'Robotics': Science,
  'Theory': Code,
  'Healthcare': HealthAndSafety,
  'Random': Casino,
  'Startups': Business,
  'Careers': Work,
};
