import React from "react";
import { all_topics_label_value } from "shared/categories/categories_transformer";
import {
  TextField,
  Button,
  Box,
  Paper,
  Typography,
  Autocomplete,
  Stack,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { Save as SaveIcon, Cancel as CancelIcon } from "@mui/icons-material";

export default function EditQuestionItem({
  editableTitle,
  setEditableTitle,
  editableContent,
  setEditableContent,
  editableTag,
  setEditableTag,
  editableIsAnonymous,
  setEditableIsAnonymous,
  updatePost,
  question,
  isPostCreator,
  setIsEdit,
}) {
  return (
    <Paper elevation={2} sx={{ p: 3, mb: 2 }}>
      <form onSubmit={updatePost}>
        <Stack spacing={3}>
          <Typography variant="h6" color="primary" gutterBottom>
            Edit Question
          </Typography>

          <TextField
            label="Title"
            variant="outlined"
            fullWidth
            value={editableTitle}
            onChange={(e) => setEditableTitle(e.target.value)}
            placeholder="Enter your question title"
          />

          <TextField
            label="Content"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            value={editableContent}
            onChange={(e) => setEditableContent(e.target.value)}
            placeholder="Enter your question details"
          />

          {question && isPostCreator && (
            <Autocomplete
              multiple
              value={editableTag}
              onChange={(event, newValue) => setEditableTag(newValue)}
              options={all_topics_label_value}
              getOptionLabel={(option) => option.label}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Topics"
                  placeholder="Select topics..."
                  variant="outlined"
                />
              )}
            />
          )}

          <FormControlLabel
            control={
              <Checkbox
                checked={editableIsAnonymous}
                onChange={(e) => setEditableIsAnonymous(e.target.checked)}
                inputProps={{ "data-testid": "checkBoxEdit" }} // Pass data-testid here
              />
            }
            label="Would you like this post to be anonymous?"
          />

          <Box sx={{ display: "flex", gap: 2, justifyContent: "flex-end" }}>
            <Button
              variant="outlined"
              color="error"
              startIcon={<CancelIcon />}
              onClick={() => setIsEdit(false)}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              data-testid="submit-button"
            >
              Save Changes
            </Button>
          </Box>
        </Stack>
      </form>
    </Paper>
  );
}
