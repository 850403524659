import React, { useCallback, useState } from "react";

import { Card, Space, Typography, Switch } from "antd";
import { BellOutlined } from "@ant-design/icons";

const { Text } = Typography;

export default function OnlineNotification() {
  // This will be the general button that turns on all of the OnlineNotification Button
  const [enableOnlineNotification, setEnableOnlineNotification] =
    useState(false); // Toggle to turn all on/off
  const [onlineNotification, setOnlineNotification] = useState({
    Likes: false,
    AnswersToQuestions: false,
  });

  const handleOnlineNotification = useCallback(
    (status) => {
      setEnableOnlineNotification(status);
      setOnlineNotification({
        Likes: status,
        AnswersToQuestions: status,
      });
    },
    [setEnableOnlineNotification, setOnlineNotification]
  );

  const handleIndividualOnlineNoti = useCallback((type) => {
    setOnlineNotification((prev) => {
      const updatedNotifications = {
        ...prev,
        [type]: !prev[type],
      };

      // Checks if any of the properties is true. If it is true, we will turn the enableOnlineNotification true to indcate to user its turned on
      const isAnyNotificationEnabled = Object.values(updatedNotifications).some(
        (value) => value
      );
      setEnableOnlineNotification(isAnyNotificationEnabled);

      return updatedNotifications;
    });
  }, []);

  return (
    <Card
      title={
        <Space>
          <BellOutlined className="text-blue-500" />
          <span>Online Notifications</span>
          <Switch
            checked={enableOnlineNotification}
            onChange={() => handleOnlineNotification(!enableOnlineNotification)}
          />
        </Space>
      }
      className="shadow-md"
    >
      <Space direction="vertical" className="w-full">
        <div className="flex justify-between items-center">
          <Text>Likes</Text>
          <Switch
            checked={onlineNotification.Likes}
            onChange={() => handleIndividualOnlineNoti("Likes")}
          />
        </div>

        <div className="flex justify-between items-center">
          <Text>Answers to Questions</Text>
          <Switch
            checked={onlineNotification.AnswersToQuestions}
            onChange={() => handleIndividualOnlineNoti("AnswersToQuestions")}
          />
        </div>
      </Space>
    </Card>
  );
}
