import React, { useState, useEffect, useContext } from "react";
import { formatTimestamp } from "../../utils/formatTimestamp";
import { message } from "antd";
import Modal from "react-modal";
import CommentForm from "./Comments/CommentForm";
import CommentList from "./Comments/CommentList";
import UserDetailsComponent from "../Users/UserDetailsComponent.js";
import { AuthContext } from "../Auth/AuthProvider.js";
import { backend_url } from "shared/config.js";
Modal.setAppElement("#root");

const customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1000,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    position: "relative",
    top: "auto",
    left: "auto",
    right: "auto",
    bottom: "auto",
    padding: 0,
    maxHeight: "90vh",
    width: "90%",
    maxWidth: "800px",
    borderRadius: "12px",
    border: "none",
    boxShadow: "0 4px 24px rgba(0, 0, 0, 0.1)",
    overflow: "visible",
    backgroundColor: "#fff",
    margin: "20px",
  },
};

const ReplyModal = ({ isOpen, onClose, reply, creator }) => {
  const { user, accessToken } = useContext(AuthContext);
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isOpen && reply) {
      fetchComments();
    }
  }, [isOpen, reply]);

  const fetchComments = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${backend_url}/reply/comments?reply_id=${reply.id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch comments");
      }

      const commentsData = await response.json();
      setComments(commentsData);
    } catch (error) {
      console.error("Error fetching comments:", error);
      alert("Failed to load comments. Please try again.");
    }
    setLoading(false);
  };

  const handleAddComment = async (content, parentId = reply.id) => {
    if (!content.trim()) return;

    if (!user) {
      alert("You must be logged in to add a comment.");
      return;
    }

    try {
      const response = await fetch(`${backend_url}/create/reply`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          type: 3,
          uid: user.uid,
          content: content,
          parent: parentId,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to add comment");
      }

      fetchComments();
      message.success("Comment added successfully");
    } catch (error) {
      console.error("Error adding comment:", error);
      alert("Failed to add comment. Please try again.");
    }
  };

  const handleDeleteComment = async (commentId) => {
    try {
      const response = await fetch(
        `${backend_url}/reply/comments/${commentId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete comment");
      }

      fetchComments();
      message.success("Comment deleted successfully");
    } catch (error) {
      console.error("Error deleting comment:", error);
      alert("Failed to delete comment. Please try again.");
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Reply Modal"
      style={customStyles}
      className="outline-none"
    >
      <div className="flex flex-col w-full h-full max-h-[90vh]">
        {/* Header */}
        <div className="p-4 border-b border-gray-200 flex justify-between items-center bg-white rounded-t-lg">
          <h2 className="text-lg font-semibold">Discussion</h2>
          <button
            onClick={onClose}
            className="p-2 hover:bg-gray-100 rounded-full transition-colors duration-200"
            aria-label="Close Modal"
          >
            <svg
              className="w-5 h-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>

        {/* Content */}
        <div className="overflow-y-auto">
          <div className="p-6">
            <UserDetailsComponent postCreator={creator} />
            <div className="mb-6 bg-gray-50 p-4 rounded-lg">
              <span className="text-sm text-gray-500">
                Posted{" "}
                {reply?.timestamp ? formatTimestamp(reply.timestamp) : "N/A"}
              </span>
              <p className="text-gray-900 mb-2 break-words whitespace-pre-wrap">
                {reply?.content}
              </p>
            </div>

            <div className="space-y-6">
              <CommentForm
                handleAddComment={handleAddComment}
                parentCommentId={reply?.id}
                user={user}
                topLevel={true}
              />

              {loading ? (
                <div className="flex justify-center items-center py-4">
                  <div className="animate-spin rounded-full h-6 w-6 border-2 border-gray-200 border-t-blue-600"></div>
                </div>
              ) : (
                <div className="space-y-4">
                  <CommentList
                    setComments={setComments}
                    comments={comments}
                    user={user}
                    handleDeleteComment={handleDeleteComment}
                    handleAddComment={handleAddComment}
                    formatTimestamp={formatTimestamp}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ReplyModal;
