import React, { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../Auth/AuthProvider.js";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  Avatar,
  Button,
  Tooltip,
  MenuItem,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Menu as MenuIcon, Search as SearchIcon } from "@mui/icons-material";
import SearchBar from "./SearchBar";

const LogoName = "Abstruse";
const Logo = process.env.PUBLIC_URL + "/LogoBGremoved.png";

function Nav() {
  const { user, userInfo, loading } = useContext(AuthContext);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  // Dynamic page navigation based on user authentication
  const pages = user ? ["Ask"] : [];
  const authPages = !user ? ["Login", "Register"] : [];
  const settings = ["Profile", "Settings", "Logout"];

  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenNavMenu = (event) => setAnchorElNav(event.currentTarget);
  const handleOpenUserMenu = (event) => setAnchorElUser(event.currentTarget);
  const handleCloseNavMenu = () => setAnchorElNav(null);
  const handleCloseUserMenu = () => setAnchorElUser(null);

  const handleSettingClick = (setting) => {
    if (setting === "Logout") {
      navigate("/logout");
    } else {
      navigate(`/${setting.toLowerCase()}/${userInfo.link}`);
    }
    handleCloseUserMenu();
  };

  return (
    <AppBar
      position="static"
      sx={{
        background: "linear-gradient(135deg, #6a11cb 0%, #2575fc 100%)",
        boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* Logo and Mobile Menu */}
          {isMobile && (
            <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
              <IconButton
                size="large"
                aria-label="menu"
                aria-controls="mobile-menu"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>

              <Box
                sx={{ flexGrow: 1, display: "flex", justifyContent: "center" }}
              >
                <Link to="/" style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={Logo}
                    alt="Logo"
                    style={{ height: "40px", width: "auto" }}
                  />
                </Link>
              </Box>

              {user && (
                <Tooltip title="Your Profile">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar
                      alt={userInfo?.firstname}
                      src={userInfo?.profilePicture}
                    />
                  </IconButton>
                </Tooltip>
              )}

              <Menu
                id="mobile-menu"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{ display: { xs: "block", md: "none" } }}
              >
                {[...pages, ...authPages].map((page) => (
                  <Link
                    to={`/${page}`}
                    key={page}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <MenuItem onClick={handleCloseNavMenu}>
                      <Typography textAlign="center">{page}</Typography>
                    </MenuItem>
                  </Link>
                ))}
              </Menu>
            </Box>
          )}

          {/* Desktop Layout */}
          {!isMobile && (
            <>
              {/* Logo */}
              <Link
                to="/"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: theme.spacing(2),
                }}
              >
                <img
                  src={Logo}
                  alt="Logo"
                  style={{
                    height: "40px",
                    width: "auto",
                    marginRight: theme.spacing(1),
                  }}
                />
                <Typography
                  variant="h6"
                  noWrap
                  sx={{
                    fontFamily: "monospace",
                    fontWeight: 700,
                    letterSpacing: ".3rem",
                    color: "inherit",
                    textDecoration: "none",
                  }}
                >
                  {LogoName}
                </Typography>
              </Link>

              {/* Navigation Pages */}
              <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
                {pages.map((page) => (
                  <Link
                    to={`/${page}`}
                    key={page}
                    style={{ textDecoration: "none" }}
                  >
                    <Button
                      onClick={handleCloseNavMenu}
                      sx={{
                        my: 2,
                        color: "white",
                        transition: "all 0.3s ease",
                        "&:hover": {
                          backgroundColor: "rgba(255,255,255,0.2)",
                          transform: "scale(1.05)",
                        },
                      }}
                    >
                      {page}
                    </Button>
                  </Link>
                ))}
              </Box>

              {/* Search and Authentication */}
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <SearchBar />

                {authPages.map((page) => (
                  <Link
                    to={`/${page}`}
                    key={page}
                    style={{ textDecoration: "none" }}
                  >
                    <Button
                      onClick={handleCloseNavMenu}
                      sx={{
                        my: 2,
                        color: "white",
                        ml: 1,
                        transition: "all 0.3s ease",
                        "&:hover": {
                          backgroundColor: "rgba(255,255,255,0.2)",
                          transform: "scale(1.05)",
                        },
                      }}
                    >
                      {page}
                    </Button>
                  </Link>
                ))}

                {user && (
                  <Tooltip title="Your Profile">
                    <IconButton
                      onClick={handleOpenUserMenu}
                      sx={{
                        p: 0,
                        ml: 2,
                        transition: "transform 0.3s ease",
                        "&:hover": {
                          transform: "scale(1.1)",
                        },
                      }}
                    >
                      <Avatar
                        alt={userInfo?.firstname}
                        src={userInfo?.profilePicture}
                        sx={{
                          width: 40,
                          height: 40,
                          border: "2px solid rgba(255,255,255,0.3)",
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
            </>
          )}

          {/* User Menu */}
          {user && (
            <Menu
              sx={{ mt: "45px" }}
              id="user-menu"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem
                  key={setting}
                  onClick={() => handleSettingClick(setting)}
                  sx={{
                    "&:hover": {
                      backgroundColor: "rgba(0,0,0,0.1)",
                    },
                  }}
                >
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Nav;
