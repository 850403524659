import React, { useState, useContext } from "react";
import { Button, Input, Modal } from 'antd';
import { backend_url } from "shared/config.js";
import { AuthContext } from "../Auth/AuthProvider.js";


const UpdateExperience = ({ profile, setProfile, index }) => {
    // If index is undefined, we are adding a new experience
    // If index is defined, we are updating an existing experience
    const [experienceEditing, setExperienceEditing] = useState(false);
    const { user, accessToken } = useContext(AuthContext);
    const adding = index == undefined;
    let okText = "Update Experience";
    if (adding) {
        okText = "Add Experience";
    }
    const [targetExperience, setTargetExperience] = adding ? 
        useState({
            jobTitle: "",
            company: "",
            startDate: "",
            endDate: "",
            description: "",
        }) : useState(profile.experience[index]);

    const handleChange = (e) => {
        setTargetExperience({
            ...targetExperience,
            [e.target.name]: e.target.value,
        });
    };
    const [errors, setErrors] = useState({});

    const validateForm = () => {
      let tempErrors = {};
      if (!targetExperience.jobTitle.trim()) tempErrors.jobTitle = "Job title is required";
      if (!targetExperience.company.trim()) tempErrors.company = "Company is required";
      if (!targetExperience.startDate) tempErrors.startDate = "Start date is required";
      if (!targetExperience.description.trim()) tempErrors.description = "Description is required";
      
      setErrors(tempErrors);
      return Object.keys(tempErrors).length === 0;
    };
  
    const submitExperience = async () => {
        try {
            if (!validateForm()) {
                return;
            }
            
            if (!adding) {
                // Delete if modifying existing experience
                const res1 = await fetch(backend_url + "/update/profile/delete_experience?uid=" + user.uid, {
                    method: "POST",
                    body: JSON.stringify({ newExperience: profile.experience[index] }),
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer: " + accessToken,
                    }
                });
            }
            const res2 = await fetch(backend_url + "/update/profile/add_experience?uid=" + user.uid, {
                    method: "POST",
                    body: JSON.stringify({ newExperience: targetExperience }),
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer: " + accessToken,
                    }
            });
            if (res2.ok) {
                if (adding) {
                    // Add new experience
                    console.log("Adding new experience");
                    setProfile({
                        ...profile,
                        experience: [
                            ...(profile.experience ? profile.experience : []),
                            targetExperience,
                        ],
                    });
                } else {
                    // Update existing experience
                    setProfile({
                        ...profile,
                        experience: [
                            ...profile.experience.slice(0, index),
                            targetExperience,
                            ...profile.experience.slice(index + 1),
                        ],
                    });
                }        
            }
        } catch (error) {
            console.error("Error adding or updating experience", user.uid, error)
        }
        setExperienceEditing(false);
    };

    const deleteExperience = async () => {
        try {
            const res = await fetch(backend_url + "/update/profile/delete_experience?uid=" + user.uid, {
                method: "POST",
                body: JSON.stringify({ newExperience: profile.experience[index] }),
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer: " + accessToken,
                }
            });
            if (res.ok) {
                setProfile({
                    ...profile,
                    experience: profile.experience.filter((_, i) => i != index),
                });
            }   
        } catch (error) {
            console.error("Error deleting experience", user.uid, error)
        }
        setExperienceEditing(false);
    };


    return (
    <div>
        <Button 
            className="absolute top-0 right-0" 
            onClick={() => setExperienceEditing(true)}
        >
            {adding ? "Add" : "Edit"}
        </Button>

        <Modal
            okText={okText}
            open={experienceEditing}
            onOk={() => {submitExperience();}}
            onCancel={() => setExperienceEditing(false)}
        >
            <form className="p-6 bg-white shadow-md rounded-md max-w-md mx-auto">
                <h2 className="text-xl font-bold mb-4">Add Work Experience</h2>

                <div className="mb-4">
                    <label htmlFor="jobTitle" className="block text-sm font-medium text-gray-700">
                        Job Title
                    </label>
                    <input
                        type="text"
                        name="jobTitle"
                        value={targetExperience.jobTitle}
                        onChange={handleChange}
                        className={`mt-1 p-2 block w-full border ${errors.jobTitle ? 'border-red-500' : 'border-gray-300'} rounded-md`}
                        required
                    />
                    {errors.jobTitle && <p className="mt-1 text-xs text-red-500">{errors.jobTitle}</p>}
                </div>

                <div className="mb-4">
                    <label htmlFor="company" className="block text-sm font-medium text-gray-700">
                        Company
                    </label>
                    <input
                        type="text"
                        name="company"
                            value={targetExperience.company}
                        onChange={handleChange}
                        className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
                        required
                    />
                    {errors.company && <p className="mt-1 text-xs text-red-500">{errors.company}</p>}

                </div>

                <div className="mb-4">
                    <label htmlFor="startDate" className="block text-sm font-medium text-gray-700">
                        Start Date
                    </label>
                    <input
                        type="date"
                        name="startDate"
                        value={targetExperience.startDate}
                        onChange={handleChange}
                        className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
                        required
                    />
                    {errors.startDate && <p className="mt-1 text-xs text-red-500">{errors.startDate}</p>}

                </div>

                <div className="mb-4">
                    <label htmlFor="endDate" className="block text-sm font-medium text-gray-700">
                        End Date
                    </label>
                    <input
                        type="date"
                        name="endDate"
                        value={targetExperience.endDate}
                        onChange={handleChange}
                        className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
                        required
                    />
                </div>

                <div className="mb-4">
                    <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                        Description
                    </label>
                    <textarea
                        name="description"
                        value={targetExperience.description}
                        onChange={handleChange}
                        rows={4}
                        className="mt-1 p-2 block w-full border border-gray-300 rounded-md"
                        required
                    />
                    {errors.description && <p className="mt-1 text-xs text-red-500">{errors.description}</p>}
                </div>

                {!adding && (
                    <button
                    type="reset"
                    onClick={() => {deleteExperience()}}
                    className="w-full py-2 px-4 bg-red-600 text-white font-semibold rounded-md hover:bg-red-700"
                    >
                    Delete
                    </button>
                )}
            </form>

        </Modal>
    </div>

    );
};

export default UpdateExperience;
