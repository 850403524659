// Set to true for production environment
export const prod = true;

// Set to "prod" to use production database, "dev" for development database
export const db_env = "prod";

// URLs and endpoints based on environment
export const backend_url = prod
  ? "https://abstru.com:4000"
  : "http://localhost:4000";
