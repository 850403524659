import React, { useState, useEffect, useRef } from "react";
import RegisterComponent from "./Auth/RegisterComponent.js";
import { motion, useMotionValue, useSpring, useTransform } from "framer-motion";
import LoginComponent from "./Auth/LoginComponent.js";
import PasswordResetComponent from "./Auth/PasswordReset.js";
import LandingCarousel from "./LandingCarousel.js";

const Blob = ({ color, initialPosition, delay }) => {
  const blobRef = useRef(null);
  const mouseX = useMotionValue(0);
  const mouseY = useMotionValue(0);

  const springConfig = { damping: 15, stiffness: 150 };
  const springX = useSpring(mouseX, springConfig);
  const springY = useSpring(mouseY, springConfig);

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (!blobRef.current) return;

      // Get blob's position and size
      const blob = blobRef.current.getBoundingClientRect();
      const blobCenterX = blob.left + blob.width / 2;
      const blobCenterY = blob.top + blob.height / 2;

      // Calculate distance from cursor to blob center
      const distX = e.clientX - blobCenterX;
      const distY = e.clientY - blobCenterY;
      const distance = Math.sqrt(distX * distX + distY * distY);

      // Only react if cursor is within 300px
      const proximityThreshold = 300;
      if (distance < proximityThreshold) {
        // Scale the movement based on proximity (closer = stronger effect)
        const scale = 1 - distance / proximityThreshold;
        mouseX.set(distX * scale * 0.5);
        mouseY.set(distY * scale * 0.5);
      } else {
        // Reset to center if cursor is far
        mouseX.set(0);
        mouseY.set(0);
      }
    };

    window.addEventListener("mousemove", handleMouseMove);
    return () => window.removeEventListener("mousemove", handleMouseMove);
  }, [mouseX, mouseY]);

  return (
    <motion.div
      ref={blobRef}
      className={`absolute w-96 h-96 ${color} rounded-full mix-blend-multiply filter blur-xl opacity-70`}
      style={{
        ...initialPosition,
        x: springX,
        y: springY,
      }}
      animate={{
        x: [0, 30, -20, 0],
        y: [0, -40, 20, 0],
        scale: [1, 1.1, 0.9, 1],
        transition: {
          duration: 20,
          repeat: Infinity,
          repeatType: "loop",
          delay,
          times: [0, 0.333, 0.667, 1],
          ease: "linear",
        },
      }}
    />
  );
};

const Landing = () => {
  const [isSignUpOpen, setSignUpOpen] = useState(false);
  const [isLoginOpen, setLoginOpen] = useState(false);
  const [isPasswordResetOpen, setPasswordResetOpen] = useState(false);

  const toggleSignUp = () => setSignUpOpen(!isSignUpOpen);
  const toggleLogin = () => setLoginOpen(!isLoginOpen);
  const togglePasswordReset = () => setPasswordResetOpen(!isPasswordResetOpen);

  return (
    <div className="min-h-screen relative overflow-hidden">
      {/* Background with animated gradient */}
      <div className="absolute inset-0 bg-gradient-to-br from-blue-50 via-indigo-50 to-violet-50">
        {/* Animated circles */}
        <Blob
          color="bg-blue-200"
          initialPosition={{ top: 0, left: 0 }}
          delay={0}
        />
        <Blob
          color="bg-indigo-200"
          initialPosition={{ top: 0, right: 0 }}
          delay={2}
        />
        <Blob
          color="bg-violet-200"
          initialPosition={{ bottom: -32, left: 80 }}
          delay={4}
        />

        {/* Grid pattern overlay */}
        <div className="absolute inset-0 bg-grid-pattern opacity-[0.02]"></div>
      </div>

      {/* Content */}
      <div className="relative min-h-screen flex flex-col items-center justify-center py-12 px-4">
        <motion.div
          className="text-center mb-8"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <motion.h1
            className="text-5xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-violet-600 mb-4"
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            Welcome to Abstruse
          </motion.h1>
          <motion.p
            className="text-xl text-gray-700 max-w-2xl mx-auto leading-relaxed"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.3 }}
          >
            Connect with academic and industry professionals, share technical
            ideas, and grow your network.
          </motion.p>
        </motion.div>

        <motion.div
          className="w-full max-w-6xl mx-auto mb-12"
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ delay: 0.2, duration: 0.5 }}
        >
          <LandingCarousel />
        </motion.div>

        <motion.div
          className="flex space-x-4"
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ delay: 0.4, duration: 0.5 }}
        >
          <div className="flex flex-col gap-y-2">
            <div className="flex gap-x-2">
              <motion.button
                onClick={toggleSignUp}
                className="px-8 py-3 bg-gradient-to-r from-blue-600 to-indigo-600 text-white font-semibold rounded-lg shadow-lg hover:from-blue-700 hover:to-indigo-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75 transform hover:scale-105 transition-all duration-200"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Sign Up
              </motion.button>
              <motion.button
                onClick={toggleLogin}
                className="px-8 py-3 bg-white text-gray-800 font-semibold rounded-lg shadow-lg hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-75 transform hover:scale-105 transition-all duration-200"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Log In
              </motion.button>
            </div>
            <p
              onClick={togglePasswordReset}
              className="text-gray-600 cursor-pointer hover:underline text-center"
            >
              Forgot your password?
            </p>
          </div>

          {/* Changed from button to text */}
        </motion.div>

        {isSignUpOpen && (
          <motion.div
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
            onClick={toggleSignUp}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
          >
            <motion.div
              className="bg-white rounded-lg p-8 shadow-lg"
              initial={{ scale: 0.8 }}
              animate={{ scale: 1 }}
              transition={{ duration: 0.3 }}
              onClick={(e) => e.stopPropagation()}
            >
              <RegisterComponent />
            </motion.div>
          </motion.div>
        )}

        {isLoginOpen && (
          <motion.div
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
            onClick={toggleLogin}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
          >
            <motion.div
              className="bg-white rounded-lg p-8 shadow-lg"
              initial={{ scale: 0.8 }}
              animate={{ scale: 1 }}
              transition={{ duration: 0.3 }}
              onClick={(e) => e.stopPropagation()}
            >
              <LoginComponent />
            </motion.div>
          </motion.div>
        )}

        {isPasswordResetOpen && (
          <motion.div
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
            onClick={togglePasswordReset}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
          >
            <motion.div
              className="bg-white rounded-lg p-8 shadow-lg"
              initial={{ scale: 0.8 }}
              animate={{ scale: 1 }}
              transition={{ duration: 0.3 }}
              onClick={(e) => e.stopPropagation()}
            >
              <PasswordResetComponent />
            </motion.div>
          </motion.div>
        )}
      </div>
    </div>
  );
};

export default Landing;
