import React, { useState } from "react";
import { backend_url } from "shared/config";

const CommentForm = ({
  handleAddComment,
  parentCommentId,
  user,
  onCancel,
  topLevel,
}) => {
  const [content, setContent] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) return;

    setIsSubmitting(true);
    await handleAddComment(content, parentCommentId);
    setContent("");
    setIsSubmitting(false);
  };

  return (
    <form onSubmit={onSubmit} className="mb-4">
      <div className="flex items-start space-x-3">
        <img
          src={user?.avatar || backend_url + "/default_profile_pic2.jpeg"}
          alt="User Avatar"
          className="w-8 h-8 rounded-full object-cover border border-gray-200"
        />
        <div className="flex-1">
          <div className="relative">
            <textarea
              value={content}
              onChange={(e) => setContent(e.target.value)}
              name="comment"
              rows={
                content.split("\n").length > 2 ? content.split("\n").length : 2
              }
              className="w-full px-4 py-2 text-sm bg-gray-50 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent focus:bg-white transition-all duration-200 resize-none"
              placeholder="Add to the discussion..."
              required
              aria-label="Comment"
            />
            <div className="absolute bottom-3 right-3 text-xs text-gray-400">
              {content.length}/1000
            </div>
          </div>

          <div className="flex items-center justify-end space-x-2 mt-2">
            {!topLevel && (
              <button
                onClick={onCancel}
                type="button"
                className="px-3 py-1.5 text-xs font-medium text-gray-600 hover:text-gray-800 transition-colors duration-200"
              >
                Cancel
              </button>
            )}
            <button
              type="submit"
              disabled={isSubmitting || content.trim() === ""}
              className={`px-4 py-1.5 rounded-lg bg-blue-600 text-white text-xs font-medium hover:bg-blue-700 transition-all duration-200 disabled:opacity-50 disabled:cursor-not-allowed flex items-center space-x-1`}
            >
              <span>{isSubmitting ? "Posting..." : "Post Comment"}</span>
              {!isSubmitting && (
                <svg
                  className="w-3 h-3"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" />
                </svg>
              )}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default CommentForm;
