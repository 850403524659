import React, { useContext, useState, useEffect } from "react";
import { Sidebar, Menu, MenuItem, menuClasses } from "react-pro-sidebar";
import { useNavigate } from "react-router-dom";
import {
  HourglassBottom,
  Diamond,
  Category,
  ContactSupport,
  DarkMode,
  LightMode,
  Settings as SettingsIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import { backend_url } from "shared/config.js";
import { useTopic } from "../../utils/TopicContext";
import { AuthContext } from "../Auth/AuthProvider";
import { all_topics } from "shared/categories/categories_transformer";
import { topicIcons } from "../../utils/TopicIcons";
import {
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Autocomplete,
  TextField,
  Chip,
  Box,
  useMediaQuery,
} from "@mui/material";

// Theme configuration
const themes = {
  light: {
    sidebar: {
      background: "#F3F4F6",
      text: "#607489",
      accent: "#0098e5",
    },
    menu: {
      hover: "#c5e4ff",
      active: "#e6f2ff",
    },
  },
  dark: {
    sidebar: {
      background: "#0b2948",
      text: "#8ba1b7",
      accent: "#59d0ff",
    },
    menu: {
      hover: "#00458b",
      active: "#13395e",
    },
  },
};

export default function Leftsidebar() {
  const navigate = useNavigate();
  const { accessToken, userInfo } = useContext(AuthContext);
  const { topic, setTopic } = useTopic();
  const isMobile = useMediaQuery("(max-width: 768px)");

  // State management
  const [isManagingTopics, setIsManagingTopics] = useState(false);
  const [userTopicInterests, setUserTopicInterests] = useState([]);
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [searchValue, setSearchValue] = useState(null);
  const [error, setError] = useState(null);
  const [collapsed, setCollapsed] = useState(false);
  const [theme, setTheme] = useState("light");

  const currentTheme = themes[theme];

  // Auto-collapse on mobile
  useEffect(() => {
    if (isMobile) {
      setCollapsed(true);
    }
  }, [isMobile]);

  // Load user topics on mount
  useEffect(() => {
    try {
      const userTopics = userInfo?.topic_interests || [];
      console.log("Loading user topics:", userTopics);
      setSelectedTopics(userTopics);
      setUserTopicInterests([...userTopics]);
    } catch (err) {
      console.error("Error loading topics:", err);
      setError("Failed to load topics");
    }
  }, [userInfo]);

  // Topic management handlers
  const handleSaveTopics = async () => {
    try {
      await fetch(`${backend_url}/update/users/preferences/topics`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer: " + accessToken,
          UserId: userInfo.uid,
        },
        body: JSON.stringify({ topics: selectedTopics }),
      });
      setUserTopicInterests([...selectedTopics]);
      setIsManagingTopics(false);
      setSearchValue(null);
    } catch (err) {
      console.error("Error saving topics:", err);
      setError("Failed to save topics");
    }
  };

  const handleAddTopic = (newTopic) => {
    if (newTopic && !selectedTopics.includes(newTopic)) {
      setSelectedTopics([...selectedTopics, newTopic]);
    }
    setSearchValue(null);
  };

  const handleRemoveTopic = (topicValue) => {
    setSelectedTopics(selectedTopics.filter((v) => v !== topicValue));
  };

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
  };

  // Render helpers
  const renderSidebarSection = (title, items) => (
    <>
      <div className="px-4 my-4">
        <Typography
          variant="body2"
          fontWeight={600}
          style={{
            opacity: collapsed ? 0 : 0.7,
            letterSpacing: "0.5px",
          }}
        >
          {title}
        </Typography>
      </div>
      <Menu menuItemStyles={menuItemStyles}>
        {items.map((item, index) => (
          <MenuItem
            key={index}
            icon={item.icon}
            active={topic === item.label}
            onClick={() => {
              setTopic(item.label);
              navigate(item.route || "/");
            }}
          >
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );

  // Menu styles
  const menuItemStyles = {
    root: {
      fontSize: "14px",
      fontWeight: 500,
      borderRadius: "8px",
      margin: "4px 8px",
    },
    icon: {
      color: currentTheme.sidebar.accent,
    },
    button: {
      "&:hover": {
        backgroundColor: currentTheme.menu.hover,
        color: currentTheme.sidebar.text,
      },
      "&.active": {
        backgroundColor: currentTheme.menu.active,
        color: currentTheme.sidebar.accent,
      },
    },
  };

  return (
    <div className={isMobile ? "hidden" : ""}>
      <Sidebar
        collapsed={collapsed}
        backgroundColor={currentTheme.sidebar.background}
        style={{
          height: "100vh",
          position: "sticky",
          top: 0,
          borderRight: "1px solid #e5e7eb",
        }}
      >
        <div className="h-full flex flex-col">
          {/* Header with theme toggle */}
          <div className="flex items-center justify-between p-4">
            <button
              onClick={() => setCollapsed(!collapsed)}
              className="hover:bg-gray-200 p-2 rounded-full"
            >
              {collapsed ? "→" : "←"}
            </button>
            <button
              onClick={toggleTheme}
              className="hover:bg-gray-200 p-2 rounded-full"
            >
              {theme === "light" ? <DarkMode /> : <LightMode />}
            </button>
          </div>

          {/* Feed Display Section */}
          {renderSidebarSection("Feed Display", [
            { label: "New", icon: <HourglassBottom />, route: "/" },
            { label: "Top", icon: <Diamond />, route: "/" },
          ])}

          {/* Topics Section */}
          <div className="px-4 my-4 flex justify-between items-center">
            <Typography
              variant="body2"
              fontWeight={600}
              style={{
                opacity: collapsed ? 0 : 0.7,
                letterSpacing: "0.5px",
              }}
            >
              Topics
            </Typography>
            <IconButton
              size="small"
              onClick={() => setIsManagingTopics(true)}
              style={{ opacity: collapsed ? 0 : 1 }}
            >
              <SettingsIcon fontSize="small" />
            </IconButton>
          </div>

          {/* Topics Menu */}
          <Menu menuItemStyles={menuItemStyles}>
            <MenuItem
              icon={<Category />}
              active={topic === "All"}
              onClick={() => {
                setTopic("All");
                navigate("/");
              }}
            >
              All
            </MenuItem>
            {userTopicInterests.map((topicValue, index) => {
              const TopicIcon = topicIcons[topicValue] || Category;
              return (
                <MenuItem
                  key={index}
                  icon={<TopicIcon />}
                  active={topic.includes(topicValue)}
                  onClick={() => {
                    setTopic(topicValue);
                    navigate("/");
                  }}
                >
                  {topicValue}
                </MenuItem>
              );
            })}
          </Menu>

          {/* Support Section */}
          <div className="px-4 my-4">
            <Typography
              variant="body2"
              fontWeight={600}
              style={{
                opacity: collapsed ? 0 : 0.7,
                letterSpacing: "0.5px",
              }}
            >
              Contact Us
            </Typography>
          </div>
          <Menu menuItemStyles={menuItemStyles}>
            <MenuItem
              key={"Contact Us"}
              icon={<ContactSupport />}
              onClick={() => {
                navigate("/contactus");
              }}
            >
              {"Contact Us"}
            </MenuItem>
          </Menu>
        </div>
      </Sidebar>

      {/* Manage Topics Dialog */}
      <Dialog
        open={isManagingTopics}
        onClose={() => {
          setIsManagingTopics(false);
          setSearchValue(null);
        }}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Typography variant="h6">Manage Topics</Typography>
            {error && (
              <Typography color="error" variant="caption">
                {error}
              </Typography>
            )}
          </Box>
        </DialogTitle>

        <DialogContent>
          <Box sx={{ mb: 2 }}>
            <Autocomplete
              value={searchValue}
              onChange={(event, newValue) => handleAddTopic(newValue)}
              options={all_topics.filter(
                (topic) => !selectedTopics?.includes(topic)
              )}
              getOptionLabel={(option) => option}
              noOptionsText={
                searchValue ? "No topics found" : "Type to search topics"
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search for topics"
                  variant="outlined"
                  fullWidth
                />
              )}
              filterOptions={(options, { inputValue }) => {
                if (!inputValue) return [];
                const lowerInput = inputValue.toLowerCase();
                return options.filter((option) =>
                  option.toLowerCase().startsWith(lowerInput)
                );
              }}
            />
          </Box>

          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
            {selectedTopics.map((topic) => (
              <Chip
                key={topic}
                label={topic}
                onDelete={() => handleRemoveTopic(topic)}
                deleteIcon={<CloseIcon />}
              />
            ))}
          </Box>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => {
              setIsManagingTopics(false);
              setSearchValue(null);
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSaveTopics}
            variant="contained"
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
