import { FileUploader } from "react-drag-drop-files";
import React, { useState } from "react";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { storage, auth } from "../Auth/firebase";
import { backend_url } from "shared/config";
const fileTypes = ["JPG", "PNG"];

export default function DragDrop() {
  const [uploadProgress, setUploadProgress] = useState(0);

  const handleChange = (file) => {
    const storageRef = ref(
      storage,
      `profilePictures/${auth.currentUser.uid}/${file.name}`
    );
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadProgress(progress);
        console.log(`Upload is ${progress}% done`);
      },
      (error) => {
        console.error("Error uploading file:", error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          console.log("File available at", downloadURL);

          fetch(backend_url + "/api/update/profilePicture", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${auth.currentUser.accessToken}`, // Include user token
            },
            body: JSON.stringify({ profilePictureUrl: downloadURL }),
          })
            .then((response) => response.json())
            .then((data) => {
              console.log("Profile picture updated:", data);
            })
            .catch((error) =>
              console.error("Error updating profile picture:", error)
            );
        });
      }
    );
  };

  return (
    <div>
      <FileUploader handleChange={handleChange} name="file" types={fileTypes} />
      <div>Upload Progress: {uploadProgress}%</div>
    </div>
  );
}
