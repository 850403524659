import React, { useState, useEffect, useCallback } from "react";
import { Layout, Card, Typography, Switch, Space, Button } from "antd";
import { BellOutlined, SaveOutlined } from "@ant-design/icons";
import axios from "axios";

const { Content } = Layout;
const { Title, Text } = Typography;

export default function EmailNotificationSetting({ uid, emailPreference }) {
  // This will be the general button that turns on all of the emailNotification
  const [enableAllEmails, setEnableAllEmail] = useState(false); // Toggle to turn all on/off
  const [emailNotification, setEmailNotification] = useState({
    DailyDigest: false,
    AnswersToQuestion: false,
    ProductUpdates: false,
  });
  const [isSaved, setIsSaved] = useState(false);

  const handleEmailToggle = useCallback(
    (status) => {
      setEnableAllEmail(status);
      setEmailNotification({
        DailyDigest: status,
        AnswersToQuestion: status,
        ProductUpdates: status,
      });
    },
    [setEnableAllEmail, setEmailNotification]
  );

  const handleIndividualEmailNoti = useCallback((type) => {
    setEmailNotification((prev) => {
      const updatedNotifications = {
        ...prev,
        [type]: !prev[type],
      };

      // Checks if any of the properties is true. If it is true, we will turn the enableEmails true to indcate to user its turned on
      const isAnyNotificationEnabled = Object.values(updatedNotifications).some(
        (value) => value
      );
      setEnableAllEmail(isAnyNotificationEnabled);

      return updatedNotifications;
    });
  }, []);

  const handleUpdateEmailPref = useCallback(async () => {
    try {
      const response = await axios.put(
        "http://localhost:4000/api/updateEmailNotification",
        {
          emailNotification,
          uid,
        }
      );

      if (response.status === 200) {
        setIsSaved(true);
        setTimeout(() => setIsSaved(false), 3000);
      }
    } catch (error) {
      console.error(error);
      setIsSaved(false);
    }
  }, [uid, emailNotification]);

  useEffect(() => {
    if (emailPreference) {
      // sets once emailPreference is passed down from Parent to Child
      setEmailNotification(emailPreference);
      let isAnyNotificationsEnabled = Object.values(emailPreference).some(
        (value) => value
      );
      if (isAnyNotificationsEnabled) {
        setEnableAllEmail(true);
      }
    }
  }, [emailPreference]);

  return (
    <Card
      title={
        <Space>
          <BellOutlined className="text-blue-500" />
          <span>Email Notifications</span>
          <Switch
            data-testid="EnableAll"
            checked={enableAllEmails}
            onChange={() => handleEmailToggle(!enableAllEmails)}
          />
          {isSaved && (
            <p className="text-md text-green-600">Successfully Saved!</p>
          )}
        </Space>
      }
      className="shadow-md"
    >
      <Space direction="vertical" className="w-full">
        <div className="flex justify-between items-center">
          <Text>Daily Digest</Text>
          <Switch
            data-testid="DailyDigest"
            checked={emailNotification.DailyDigest}
            onChange={() => handleIndividualEmailNoti("DailyDigest")}
          />
        </div>

        <div className="flex justify-between items-center">
          <Text>Answers to Questions</Text>
          <Switch
            data-testid="AnswersToQuestion"
            checked={emailNotification.AnswersToQuestion}
            onChange={() => handleIndividualEmailNoti("AnswersToQuestion")}
          />
        </div>

        <div className="flex justify-between items-center">
          <Text>Product Updates</Text>
          <Switch
            data-testid="ProductUpdates"
            checked={emailNotification.ProductUpdates}
            onChange={() => handleIndividualEmailNoti("ProductUpdates")}
          />
        </div>

        <div className="flex justify-end mt-4">
          <Button
            data-testid="SaveButton"
            type="primary"
            onClick={handleUpdateEmailPref}
            icon={<SaveOutlined />}
          >
            Save
          </Button>
        </div>
      </Space>
    </Card>
  );
}
