import React from "react";
import Leftsidebar from "./Sidebar/Sidebar.js";
import PostsContainer from "./Posts/PostsContainer.js";
import { useTopic } from "../utils/TopicContext.js";
const Home = () => {
  const { topic, setTopic } = useTopic();

  return (
    <div className="flex flex-wrap flex-row w-full h-full">
      <div className="flex flex-row w-full bg-gray-100">
        <Leftsidebar />

        <PostsContainer
          topic={topic}
          className="w-full max-w-xl mx-auto p-4 box-border w"
        />
      </div>
    </div>
  );
};

export default Home;
