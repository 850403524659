import { useEffect, useContext, useState, useCallback } from "react";
import { commentIcon } from "../SvgIcons.jsx";
import { AuthContext } from "../Auth/AuthProvider.js";
import UserDetailsComponent from "../Users/UserDetailsComponent.js";
import PostBottom from "./PostBottom.js";
import EditAnswerItem from "./EditAnswerItem.js";
import { formatDistanceToNow } from "date-fns";
import { backend_url } from "shared/config.js";

const AnswerItem = ({ post, postCreator, onDelete }) => {
  const content = post?.content;
  const question = post?.type == 1;
  const timestamp = post?.timestamp;

  const { user, accessToken } = useContext(AuthContext);
  const [isPostCreator, setIsPostCreator] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [relativeTimestamp, setRelativeTimestamp] = useState("");
  const [timestampError, setTimestampError] = useState(false);

  useEffect(() => {
    if (!timestamp) {
      setRelativeTimestamp("N/A");
      setTimestampError(true);
      return;
    }

    try {
      let date;
      if (typeof timestamp.seconds === "number") {
        date = new Date(
          timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000
        );
      } else if (typeof timestamp._seconds === "number") {
        date = new Date(
          timestamp._seconds * 1000 + timestamp._nanoseconds / 1000000
        );
      } else {
        throw new Error("Timestamp structure is incorrect.");
      }

      setRelativeTimestamp(formatDistanceToNow(date, { addSuffix: true }));
      setTimestampError(false);
    } catch (error) {
      console.error("Error formatting timestamp:", error);
      setRelativeTimestamp("N/A");
      setTimestampError(true);
    }
  }, [timestamp]);

  const [editableContent, setEditableContent] = useState(content);

  const changeEditStatus = () => {
    setIsEdit(!isEdit);
  };

  const updatePost = useCallback(
    async (event) => {
      event.preventDefault();
      try {
        const updatedReply = {
          replyId: post.id,
          content: editableContent,
        };

        const response = await fetch(`${backend_url}/edit/reply`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
          },
          body: JSON.stringify(updatedReply),
        });

        if (!response.ok) {
          throw new Error("Failed to update reply");
        }

        const { reply } = await response.json();

        post.content = reply.content;
        post.timestamp = reply.timestamp;
        setEditableContent(reply.content);
        setIsEdit(false);
      } catch (error) {
        console.error("Error updating reply:", error);
      }
    },
    [editableContent, question, isPostCreator]
  );

  useEffect(() => {
    if (user?.uid && postCreator?.uid) {
      setIsPostCreator(postCreator?.uid === user?.uid);
    }
  }, [user, postCreator]);

  return (
    <div className="flex flex-col border rounded bg-white relative border-zinc-300 drop-shadow">
      <UserDetailsComponent postCreator={postCreator} />

      {/* Relative Timestamp */}
      {!timestampError && (
        <div className="text-xs text-gray-500 px-1">
          Posted {relativeTimestamp}
        </div>
      )}

      {/* Content */}
      <div className="flex flex-col relative">
        {!isEdit ? (
          <>
            <div
              className="whitespace-pre-wrap px-1 break-words overflow-wrap-anywhere"
              data-testid={`${post.id}`}
            >
              {content}
            </div>
          </>
        ) : (
          <EditAnswerItem
            updatePost={updatePost}
            setEditableContent={setEditableContent}
            editableContent={editableContent}
          />
        )}
      </div>
      <PostBottom
        changeEditStatus={changeEditStatus}
        isEdit={isEdit}
        post={post}
        postCreator={postCreator}
        isPostCreator={isPostCreator}
        commentIconBool={true}
        onDelete={onDelete}
        reply_page={true}
      />
    </div>
  );
};

export default AnswerItem;
