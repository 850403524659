import React, { useState, useContext } from "react";
import { Popconfirm } from "antd";
import CommentForm from "./CommentForm";
import UserDetailsComponent from "../../Users/UserDetailsComponent";
import { commentIcon } from "../../SvgIcons";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditComment from "./EditComment.jsx";
import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";
import CloseIcon from "@mui/icons-material/Close";
import { backend_url } from "shared/config";
import { AuthContext } from "../../Auth/AuthProvider.js";

const Comment = ({
  setComments,
  comment,
  user,
  handleDeleteComment,
  handleAddComment,
  formatTimestamp,
}) => {
  const { accessToken } = useContext(AuthContext);
  const [isReplying, setIsReplying] = useState(false);
  const toggleReplyForm = () => setIsReplying(!isReplying);

  const [editCommentId, setEditCommentId] = useState(null);
  const [editableContent, setEditableContent] = useState("");

  const [editState, setEditState] = useState({});

  // keeps track of what comment message and ID
  const handleCommentChange = (commentId, currentContent) => {
    // User clicks and turns off edited
    if (editCommentId === commentId) {
      setEditCommentId(null);
      setEditableContent("");
      setEditState((prevState) => ({
        ...prevState,
        [commentId]: false,
      }));
    } else {
      // User clicks edit on particular Post. Updates the states for information of that post

      setEditCommentId(commentId);
      setEditableContent(currentContent);
      setEditState((prevState) => ({
        ...prevState,
        [commentId]: true,
      }));
    }
  };

  const updateNestedComment = (comments, updatedComment) => {
    return comments.map((comment) => {
      if (comment.id === updatedComment.id) {
        // Update the matching comment
        return { ...comment, ...updatedComment };
      }
      if (comment.replies && comment.replies.length > 0) {
        // Recursively update replies
        return {
          ...comment,
          replies: updateNestedComment(comment.replies, updatedComment),
        };
      }
      return comment;
    });
  };

  const handleSubmitEdit = async (commentId, content, parent) => {
    try {
      const response = await fetch(`${backend_url}/comments/edit`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          commentId,
          content,
          parent,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to edit comment");
      }

      const updatedComment = await response.json();

      // Use recursive function to update the state
      setComments((prevComments) =>
        updateNestedComment(prevComments, updatedComment)
      );

      setEditCommentId(null);
      setEditableContent("");
      setEditState((prevState) => ({
        ...prevState,
        [commentId]: false,
      }));
    } catch (error) {
      console.error("Error editing comment:", error);
    }
  };

  return (
    <div className="mb-4 p-y border-t rounded-lg relative ">
      <UserDetailsComponent postCreator={comment.creator} />
      <span className="text-sm text-gray-500">
        Posted {formatTimestamp(comment.timestamp)}
      </span>
      <div className="pl-3">
        {editState[comment.id] && user && user.uid === comment.uid ? (
          <EditComment
            handleSubmitEdit={handleSubmitEdit}
            editableContent={editableContent}
            setEditableContent={setEditableContent}
            comment={comment}
          />
        ) : (
          <p className="mr-2 text-gray-800" data-testid="comment-content">
            {comment.content}
          </p>
        )}
        <div className="flex justify-between mt-2 text-sm">
          <button
            onClick={toggleReplyForm}
            className="text-blue-500 hover:underline"
          >
            {commentIcon}
          </button>
          {user?.uid === comment.uid && (
            <div className="flex space-x-2">
              <button
                data-testid="comment-edit-button"
                onClick={() => handleCommentChange(comment.id, comment.content)}
              >
                {editState[comment.id] ? (
                  <Tooltip title="Cancel Edit">
                    <CloseIcon />
                  </Tooltip>
                ) : (
                  <Tooltip title="Edit Post">
                    <EditIcon />
                  </Tooltip>
                )}
              </button>
              <Popconfirm
                title="Are you sure you want to delete this comment?"
                onConfirm={() => handleDeleteComment(comment.id)}
                okText="Yes"
                cancelText="No"
              >
                <button className="hover:underline">
                  <DeleteOutlineIcon />
                </button>
              </Popconfirm>
            </div>
          )}
        </div>
      </div>
      {isReplying && (
        <div className="ml-8 mt-6">
          <CommentForm
            handleAddComment={handleAddComment}
            parentCommentId={comment.id}
            user={user}
            onCancel={toggleReplyForm}
          />
        </div>
      )}
      {comment.replies && comment.replies.length > 0 && (
        <div className="ml-4 mt-4 border-l-2 pl-4">
          {comment.replies.map((reply) => (
            <Comment
              key={reply.id}
              comment={reply}
              user={user}
              handleDeleteComment={handleDeleteComment}
              handleAddComment={handleAddComment}
              formatTimestamp={formatTimestamp}
              setComments={setComments} //passing this prop for replies
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default Comment;
