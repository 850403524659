import { Link } from "react-router-dom";
import { AtSign } from "lucide-react";

export default function UserDetailsComponent({ postCreator }) {
  return (
    <div className="flex justify-between px-3 py-2.5 items-center">
      <div className="flex space-x-3 items-center">
        <Link to={`/profile/` + postCreator?.link}>
          <img
            draggable="false"
            src={postCreator?.profilePicture}
            className="w-10 h-10 rounded-full object-cover"
            alt="avatar"
          />
        </Link>

        <div className="flex flex-col">
          <div className="flex items-center space-x-1">
            <Link
              to={`/profile/${postCreator?.link}`}
              className="text-gray-900 font-medium truncate hover:underline"
            >
              {postCreator?.firstname} {postCreator?.lastname}
            </Link>
            <AtSign size={16} className="text-gray-400" />
            <span className="text-gray-500 text-sm truncate">
              {postCreator?.email}
            </span>
          </div>

          <div className="text-black text-sm">{postCreator?.role}</div>
        </div>
      </div>
    </div>
  );
}
