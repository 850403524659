import React, { useState, useContext, useEffect, useCallback } from "react";
import {
  Layout,
  Card,
  Typography,
  Button,
  Switch,
  Divider,
  Form,
  Input,
  Space,
  List,
  message,
} from "antd";
import {
  MailOutlined,
  BellOutlined,
  CreditCardOutlined,
  UserOutlined,
  PlusOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { auth } from "../Auth/firebase.js";
import { backend_url } from "shared/config.js";

import { AuthContext } from "../Auth/AuthProvider.js";
import {
  getAuth,
  sendEmailVerification,
  updateEmail,
  deleteUser,
  sendPasswordResetEmail,
} from "firebase/auth";
import EmailNotificationSetting from "./Settings/EmailNotificationSetting.js";
import SendPasswordReset from "./Settings/SendPasswordReset.js";
import OnlineNotification from "./Settings/OnlineNotification.js";
import EmailSetting from "./Settings/EmailSetting.js";

const { Content } = Layout;
const { Title, Text } = Typography;

const UserSettings = () => {
  const { user, userInfo, loading, accessToken } = useContext(AuthContext);

  const [newEmail, setNewEmail] = useState("");
  const [emails, setEmails] = useState([]);
  const [showEmail, setShowEmail] = useState(false);

  // TODO
  useEffect(() => {
    if (!loading) {
      setEmails([{ email: user.email, isPrimary: true }]);
      setShowEmail(userInfo.showEmail);
    }
  }, [loading]);

  //TODO
  const resendVerification = async (email) => {
    try {
      if (email === user.email) {
        await sendEmailVerification(user);
      } else {
        // For secondary emails, you'd need to implement a custom solution
        // Firebase doesn't directly support multiple email verifications
        console.log("Verification for secondary emails not implemented");
      }
      message.success(`Verification email sent to ${email}!`);
    } catch (error) {
      message.error(`Failed to send verification email: ${error.message}`);
    }
  };

  // TODO
  const addNewEmail = async () => {
    if (newEmail && !emails.some((e) => e.email === newEmail)) {
      try {
        setEmails([...emails, { email: newEmail, isPrimary: false }]);
        setNewEmail("");
        message.success("New email added successfully!");
      } catch (error) {
        message.error(`Failed to add new email: ${error.message}`);
      }
    }
  };

  // TODO
  const removeEmail = async (emailToRemove) => {
    try {
      setEmails(emails.filter((e) => e.email !== emailToRemove));
      message.success("Email removed successfully!");
    } catch (error) {
      message.error(`Failed to remove email: ${error.message}`);
    }
  };

  const handleSendPasswordResetEmail = async () => {
    const email = user.email;
    try {
      await sendPasswordResetEmail(auth, email);
      message.success("Password reset email sent successfully!");
    } catch (error) {
      message.error(`Failed to send password reset email: ${error.message}`);
    }
  };

  const handleEmailVisibility = async () => {
    try {
      const profile_update = { showEmail: !showEmail };
      userInfo.showEmail = !showEmail;
      fetch(backend_url + "/update/profile?uid=" + user.uid, {
        method: "POST",
        body: JSON.stringify(profile_update),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer: " + accessToken,
        },
      });
      setShowEmail(!showEmail);
    } catch (error) {
      message.error(`Failed to update email visibility: ${error.message}`);
    }
  };

  return (
    <div className="flex flex-wrap">
      <Layout className="min-h-screen bg-gray-100">
        <Content className="p-6">
          <div className="max-w-4xl mx-auto">
            <Title level={2} className="mb-6">
              User Settings
            </Title>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <EmailSetting
                showEmail={showEmail}
                handleEmailVisibility={handleEmailVisibility}
                emails={emails}
                resendVerification={resendVerification}
                removeEmail={removeEmail}
                user={user}
                newEmail={newEmail}
                setNewEmail={setNewEmail}
                addNewEmail={addNewEmail}
              />
              <EmailNotificationSetting
                uid={userInfo?.uid}
                emailPreference={userInfo?.emailPreference}
              />

              <OnlineNotification />

              <SendPasswordReset
                handleSendPasswordResetEmail={handleSendPasswordResetEmail}
              />
            </div>
          </div>
        </Content>
      </Layout>
    </div>
  );
};

export default UserSettings;
