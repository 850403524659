import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import Leftsidebar from "../Sidebar/Sidebar.js";
import InfiniteScroll from "react-infinite-scroll-component";
import QuestionItem from "./QuestionItem.js";
import AnswerItem from "./AnswerItem.js";
import { backend_url } from "shared/config.js";
import { AuthContext } from "../Auth/AuthProvider.js";

const AnswersPage = () => {
  const { user, userInfo, accessToken } = useContext(AuthContext);

  const [reply, setReply] = useState("");
  const [answersAndCreators, setAnswersAndCreators] = useState([]);
  const [postCreator, setPostCreator] = useState(null);
  const [post, setPost] = useState(null);
  const { postId } = useParams();
  const [hasMorePosts, setHasMorePosts] = useState(false);

  useEffect(() => {
    fetch(backend_url + "/api/answers_page_from_id?post_id=" + postId, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: user?.uid ?? "",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setPostCreator(data.postCreator);
        setPost(data.question);
        setAnswersAndCreators(data.answers);
        return data;
      })
      .catch((error) => {
        console.error("Error retrieving post or children", error);
      });
  }, [postId, user]);

  const handleSubmitReply = async (e) => {
    e.preventDefault();
    try {
      const newAnswer = {
        type: 2, // 2 is an answer
        content: reply,
        parent: postId,
        uid: user.uid,
      };

      const response = await fetch(`${backend_url}/create/reply`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer: " + accessToken,
        },
        body: JSON.stringify(newAnswer),
      });

      if (response.ok) {
        const data = await response.json();
        // Add the new reply and creator details to the state
        setAnswersAndCreators((answersAndCreators) => [
          {
            answer: { id: data.replyId, ...data.reply },
            creator: data.creator,
          },
          ...answersAndCreators,
        ]);
        setReply("");
      } else {
        const errorData = await response.json();
        console.error("Error creating reply:", errorData.error);
      }
    } catch (error) {
      console.error("Error creating reply:", error);
    }
  };
  const handleDeletePost = (postId) => {
    setAnswersAndCreators((prevItems) =>
      prevItems.filter((item) => item.answer.id !== postId)
    );
  };

  return (
    <div className="flex flex-wrap w-full h-full">
      <div className="flex flex-row w-full bg-gray-100">
        <Leftsidebar />

        <main className="flex flex-col grow p-6 overflow-x-hidden overflow-y-auto">
          <QuestionItem
            key={postId}
            post={post}
            postCreator={postCreator}
            reply_page={true}
          />

          {user ? (
            <form onSubmit={handleSubmitReply} className="mb-6">
              <textarea
                rows={3}
                value={reply}
                onChange={(e) => setReply(e.target.value)}
                type="text"
                name="reply"
                className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 mt-4 overflow-wrap-anywhere"
                placeholder="Answer question here..."
              />
              <button
                type="submit"
                className="mt-4 px-4 py-2 bg-indigo-600 text-white rounded-lg shadow-md hover:bg-indigo-700 transition duration-200"
              >
                SEND
              </button>
            </form>
          ) : (
            <div className="text-center text-gray-500">
              <p className="text-center text-gray-500">
                Please log in to participate.
              </p>
            </div>
          )}

          <InfiniteScroll
            dataLength={answersAndCreators.length}
            hasMore={hasMorePosts}
            loader={<h4>Loading...</h4>}
            endMessage={
              <p style={{ textAlign: "center" }}>
                <b>No more answers to show</b>
              </p>
            }
            className="space-y-4"
          >
            <div className="space-y-4">
              {answersAndCreators?.map((item) => (
                <AnswerItem
                  key={item.answer.id}
                  item={item}
                  post={item.answer}
                  postCreator={item.creator}
                  onDelete={handleDeletePost}
                />
              ))}
            </div>
          </InfiniteScroll>
        </main>
      </div>
    </div>
  );
};

export default AnswersPage;
