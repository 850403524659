import { onAuthStateChanged } from "firebase/auth";
import { createContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { auth } from "./firebase.js";
import { backend_url } from "shared/config.js";

export const AuthContext = createContext(null);

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [accessToken, setAccessToken] = useState(null);
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      try {
        setUser(currentUser);
        if (currentUser) {
          setAccessToken(await currentUser.getIdToken(true));
          let res = await fetch(
            backend_url + "/api/userfromid?user_id=" + currentUser.uid,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          setUserInfo(await res.json());
        }
        // Loading must be set to False last once everything else has loaded.
        setLoading(false);
      } catch (e) {
        console.log("Error with setting authentication state app-wide", e);
      }
    });

    return () => unsubscribe();
  }, []);

  const authValue = {
    user,
    userInfo,
    loading,
    accessToken,
  };

  return (
    <AuthContext.Provider value={authValue}>{children}</AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthProvider;
