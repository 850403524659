import React from "react";
import { Card, Space, Typography, Button } from "antd";
import { UserOutlined, MailOutlined } from "@ant-design/icons";

const { Text } = Typography;

export default function SendPasswordReset({ handleSendPasswordResetEmail }) {
  return (
    <Card
      title={
        <Space>
          <UserOutlined className="text-blue-500" />
          <span>Account Settings</span>
        </Space>
      }
      className="shadow-md"
    >
      <Space direction="vertical" className="w-full">
        <div className="flex justify-between items-center">
          <Text>Reset Password</Text>
          <Button
            type="primary"
            onClick={handleSendPasswordResetEmail}
            icon={<MailOutlined />}
          >
            Send Reset Email
          </Button>
        </div>
      </Space>
    </Card>
  );
}
