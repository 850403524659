import React from "react";

export default function EditComment({
  handleSubmitEdit,
  editableContent,
  setEditableContent,
  comment,
}) {
  return (
    <form
      data-testid="comment-textarea"
      className="w-[95%] mx-auto p-3 border border-indigo-600 border-solid rounded-md"
      onSubmit={(e) => {
        e.preventDefault();

        handleSubmitEdit(comment.id, editableContent, comment.parent);
      }}
    >
      <label className="block m-2 text-md font-semibold underline text-gray-700 mb-1">
        Content
      </label>
      <textarea
        onChange={(e) => setEditableContent(e.target.value)}
        className="whitespace-pre-wrap w-full py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        value={editableContent}
      />

      <div className="flex justify-end">
        <button
          type="submit"
          className="m-2 py-1 px-3 bg-blue-500 text-white rounded-md hover:bg-blue-600"
        >
          Save
        </button>
      </div>
    </form>
  );
}
